<template>
  <div class="footer" style="margin-top: 50px">
    <div class="footer_top inner">
      <div class="footer_center">
        <div class="inner code6">
          <a
            href="javascript:;"
            v-for="item in gameListData || []"
            :key="item.id"
            @click="to(item.id)"
          >
            {{ item.name }}
          </a>
        </div>
        <div class="inner" style="margin-top: 10px">
          <p>
            法律声明 © 2024 版权所有 <a href="https://letgofly.com">放飞科技</a>
            <a href="http://beian.miit.gov.cn" style="border-right: 0; padding: 0" rel="nofollow"
              >&nbsp;&nbsp;皖ICP备2022001725号-1</a
            >
          </p>
        </div>
        <div class="inner" style="margin-top: 10px">
          <p></p>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import { gameList } from '@/api'
export default {
  name: 'cFooter',
  components: {},
  data() {
    return {
        gameListData: []
    }
  },
  computed: {},
  created() {
      this.getList()
  },
  methods: {
    to(gameId) {
        this.$router.push({
            path: '/list',
            query: {
            gameId,
            }
        })
    },
    async getList() {
      let { data } = await gameList()
      this.gameListData = data
        console.log(this.gameListData)
    },

  }
}
</script>

<style lang="less" scoped>
.footer {
  background-color: #1a2129;
}

.footer_top {
  padding: 20px 0;
  overflow: hidden;
}

.footer_top_ul {
  float: left;
  overflow: hidden;
}

.footer_top_ul li {
  float: left;
  width: 150px;
  text-align: center;
}

.footer_top_ul li h3 {
  font-size: 16px;
  line-height: 34px;
  color: #ffffff;
}

.footer_top_ul li a {
  display: block;
  font-size: 13px;
  line-height: 30px;
  color: #ffffff;
}

.footer_top_ul li a:hover {
  color: #ff9600;
}

.footer_top_ewm {
  float: left;
  width: 100px;
  margin: 0 50px;
}

.footer_top_ewm img {
  display: block;
  margin: 0 auto;
  height: 96px;
}

.footer_top_ewm p {
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin-top: 8px;
}

.footer_center {
  padding: 14px 0;

  text-align: center;
}

.footer_center a,
.footer_center p {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  padding: 0 10px;
  border-right: 1px solid #ffffff;
}

.footer_center a:hover {
  color: #ff9600;
}

.footer_center p {
  border-right: 0;
}

.footer_center p img {
  height: 20px;
  margin: 0 10px;
}

.footer_bottom {
  background-color: #35353b;
  padding: 20px 0 30px;
  text-align: center;
}

.footer_bottom a {
  display: inline-block;
  margin: 0 15px;
}

.footer_bottom a img {
  width: 106px;
  height: 42px;
}
</style>
